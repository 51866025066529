<template>
  <div class="right">
    <template v-if="!isSuccess">
      <div class="title">{{ $t('el.register.register') }}</div>
      <!-- <div class="title">VaaS</div>
            <div class="topic">链必验-智能合约形式化验证平台</div>-->
      <div class="form">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="email">
            <el-input
              v-model.trim="form.email"
              :placeholder="$t('el.login.accountOrEmailP')"
              clearable
              maxlength="40"
            >
              <template slot="prefix">
                <img src="@/assets/images/login/email.png" alt />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model.trim="form.password"
              :placeholder="$t('el.inputPW')"
              maxlength="12"
              :type="visibleAgain ? 'text' : 'password'"
            >
              <template slot="prefix">
                <img src="@/assets/images/login/password.png" alt />
              </template>
              <template slot="append">
                <img
                  class="showIcon"
                  v-if="!visibleAgain"
                  @click="visibleAgain = !visibleAgain"
                  src="@/assets/images/hide.png"
                  alt
                />
                <img
                  class="showIcon"
                  v-else
                  @click="visibleAgain = !visibleAgain"
                  src="@/assets/images/show.png"
                  alt
                />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="flex">
              <el-input
                prefix-icon="el-icon-key"
                v-model.trim="form.code"
                :placeholder="$t('el.verificationCode')"
                clearable
                maxlength="6"
              ></el-input>

              <img :src="codeUrl" @click="getPicCode" class="login-code-img" />
            </div>
          </el-form-item>

          <el-form-item prop="verification_code">
            <div class="flex">
              <el-input
                prefix-icon="el-icon-key"
                v-model.trim="form.verification_code"
                :placeholder="$t('el.verificationCode')"
                clearable
                style="width:260px"
                maxlength="6"
              ></el-input>
              <div class="login-code">
                <div class="getcode" v-if="!tip" @click="getCode">
                  <img src="@/assets/images/login/email2.png" alt />
                  {{ $t('el.getVerCode') }}
                </div>
                <div class="getcode" v-else>{{ number }}s {{ $t('el.reGetVerCode') }}</div>
              </div>
            </div>
          </el-form-item>

          <el-button
            id="login_btn"
            :loading="loading"
            size="medium"
            type="primary"
            class="sub-btn"
            style="width: 100%; margin-bottom: 30px"
            @click.native.prevent="handRegister"
          >
            <span v-if="!loading">{{ $t('el.register.register') }}</span>
            <span v-else>{{ $t('el.register.registering') }}...</span>
          </el-button>
        </el-form>
        <div class="right-bottom-btn">
          <div @click="$emit('to', 'login')">{{ $t('el.resetPassword.goLogin') }}</div>
        </div>
      </div>
    </template>
    <Success v-else :title="$t('el.register.registerSuccess')" @to="$emit('to', 'login')"></Success>
  </div>
</template>
<script>
import { Message } from 'element-ui'
import { Base64 } from 'js-base64'
import { verifyCode, registerAccount, getCodeImg } from '../../api/login'
import Success from './success.vue'
export default {
  components: {
    Success
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('el.login.accountOrEmailP')))
      } else if (!this.emailReg.test(value)) {
        callback(new Error(this.$t('el.login.accountOrEmailTip')))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('el.inputPW')))
      } else if (!this.pwdReg.test(value)) {
        callback(new Error(this.$t('el.tipPWF')))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error(this.$t('el.verCodeP')))
      } else {
        callback()
      }
    }
    return {
      form: {
        email: '',
        password: '',
        code: '',
        uuid: '',
        verification_code: ''
      },
      loading: false,
      rules: {
        email: [{ required: true, trigger: 'blur', validator: validateEmail }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        code: [{ required: true, trigger: 'blur', validator: validateCode }],
        verification_code: [{ required: true, trigger: 'blur', validator: validateCode }]
      },
      //是否重新获得验证码
      tip: false,
      number: null,
      visibleAgain: false,
      visible: false,
      isSuccess: false,
      codeUrl: ''
    }
  },
  created() {
    this.getPicCode()
  },
  methods: {
    getPicCode() {
      getCodeImg().then(({ data }) => {
        this.codeUrl = 'data:image/gif;base64,' + data.img
        this.form.uuid = data.uuid
      })
    },
    //处理注册
    handRegister() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const { email, password, code } = this.form
          this.loading = true
          registerAccount({
            account: email,
            password: Base64.encode(password),
            verification_code: this.form.verification_code,
            uuid: this.form.uuid,
            code: this.form.code,
            from: 'beosin-vaas'
          })
            .then(res => {
              //注册成功
              Message.success(this.$t('el.register.registerSuccess'))
              this.loading = false
              this.isSuccess = true
            })
            .catch(err => {
              this.loading = false
              this.getPicCode()
              return false
            })
        }
      })
    },
    //获得注册的验证码
    getCode() {
      this.$refs['form'].validateField('email', error => {
        if (error) return
        this.$refs['form'].validateField('code', error => {
          if (error) return
          verifyCode({
            userName: String(this.form.email),
            code: this.form.code,
            uuid: this.form.uuid
          }).then(res => {
            this.$message.success(this.$t('el.getVerCodeSuccess'))
            console.log(res, 'res')
            this.tip = true
            this.form.uuid = res.data
            this.number = 60
            const codeInterval = setInterval(() => {
              if (this.number > 0) {
                this.number--
              } else {
                clearInterval(codeInterval)
                this.tip = false
              }
            }, 1000)
          })
          .catch(() => {
            this.getPicCode()
          })
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  .login-code-img {
    margin-left: 10px;
    height: 48px;
    flex: 0 0 auto;
  }
  .getcode {
    height: 48px;
    font-size: 14px;
    cursor: pointer;
    color: #1cd2a9;
    line-height: 48px;
    text-align: center;
    padding: 0 5px;
    border-radius: 4px;
    img {
      vertical-align: middle;
      margin-right: 3px;
    }
  }
}
</style>
