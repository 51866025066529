/*
 * @utils.js
 * @deprecated
 * @author czh
 * @update (czh 2021/12/28)
 */
import Vue from 'vue'
import JSZip, { file } from 'jszip'
/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}
Vue.prototype.setStore = setStore

/**
 * 获取localStorage
 */
export const getStore = name => {
  if (!name) return
  return window.localStorage.getItem(name)
}
Vue.prototype.getStore = getStore

/**
 * 删除localStorage
 */
export const removeStore = name => {
  if (!name) return
  window.localStorage.removeItem(name)
}
Vue.prototype.removeStore = removeStore

export const getQueryVariable = variable => {
  let query = window.location.href
  let vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}
Vue.prototype.getQueryVariable = getQueryVariable

export const getUrlkey = url => {
  let params = {}
  if (url.indexOf('?') > -1) {
    //判断如果请求地址中包含参数
    let urls = url.split('?')
    let arr = urls[1].split('&')
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split('=')
      params[a[0]] = a[1]
    }
  }
  return params
}
Vue.prototype.getUrlkey = getUrlkey

/**
 *
 * @param {file.zip[]} zips
 * @param {后缀名} ext
 * @returns [{code:string,name:string}] data
 */
export async function zipsToFile(zips, ext) {
  const res = []
  const dirs = new Set()
  await dps(zips, ext)
  return Promise.all(res)
  async function dps(zips, ext) {
    if (!zips.length) return
    let files = await Promise.all(
      zips.map(it => {
        const zip = new JSZip()
        zip.root = it.name.replace(/\.zip$/, '/')
        return zip.loadAsync(it.file).catch(err => {
          throw {name: it.name, err}
        })
      })
    )
    let nextZips = []
    for (let it of files) {
      const fileObj = it.files
      Object.keys(fileObj).forEach(path => {
        const v = fileObj[path]
        if (v.name.endsWith(ext)) {
          res.push(
            Promise.resolve(v.async('string')).then(code => ({
              code,
              name: v.name
            }))
          )
        } else if (v.name.endsWith('.zip')) {
          nextZips.push(
            Promise.resolve(v.async('blob')).then(file => ({
              file,
              name: v.name
            }))
          )
        } else if (v.dir) {
          dirs.add(v.name)
        }
      })
    }
    nextZips = await Promise.all(nextZips)
    return dps(nextZips, ext)
  }
}

/**
 *
 * @param {[{code:'xxx',name:'c/b.sol'}]} arr
 * @returns {[[{name:'c',type:'dir'}],[{name:'b.sol',code:'xx',type:'code'}]]}
 * @description 将文件按照目录深度分级，以便于去请求创建文件夹和文件（后端先要创建文件夹，用文件夹的id去创建文件）
 */

/**
 *
 * @param {[{code:'xxx',name:'c/b.sol'},{code:'xxx',name:'c/c.sol'}]} arr
 * @returns {{type:'dir',children:data[],name:'c'}[]} data
 */
export const pathTree = arr => {
  let res = []
  const record = {}
  for (let it of arr) {
    const paths = it.name.split('/')
    let node = record
    let arr = res
    for (let i = 0; i < paths.length - 1; i++) {
      const dir = paths[i]
      if (!node[dir]) {
        node[dir] = { val: { name: dir, type: 'dir', children: [] } }
        arr.push(node[dir].val)
      }
      node = node[dir]
      arr = node.val.children
    }
    arr.push({ name: paths[paths.length - 1], type: 'code', code: it.code })
  }
  return res
}
