<template>
    <div class="login-dialog-main">
        <Left></Left>
        <LoginHome @to="setActive" @success="$emit('success')" v-if="active === 'login'"></LoginHome>
        <PhoneLogin @to="setActive" @success="$emit('success')" v-else-if="active === 'phoneLogin'"></PhoneLogin>
        <ResetPassword @to="setActive" v-else-if="active === 'resetPassword'"></ResetPassword>
        <Register @to="setActive" v-else-if="active === 'register'"></Register>
    </div>
</template>

<script>
import LoginHome from './login.vue'
import PhoneLogin from './phoneLogin.vue'
import ResetPassword from './reset-password.vue'
import Register from './register.vue'
import Left from './left.vue'

export default {
    components: {
        LoginHome,
        PhoneLogin,
        ResetPassword,
        Register,
        Left
    },
    data() {
        return {
            active: 'login'
        }
    },
    methods: {
        setActive(to) {
            console.log('to', to)
            this.active = to
        },
    }
}
</script>


<style lang="scss" >
.login-dialog-main {
    width: 1080px;
    height: 620px;
    background: url(~assets/images/login/bg.png) no-repeat;
    display: flex;
    z-index: 10;
    background-size: 100% 100%;
    .right {
        width: 400px;
        .title {
            margin-top: 78px;
            font-size: 24px;
            line-height: 32px;
            color: #fff;
            font-weight: 600;
            margin-bottom: 24px;
        }
        .showIcon {
            height: 18px;
            position: relative;
            top: 1px;
            display: inline-block;
            cursor: pointer;
        }
    }
    .right-bottom-btn {
        display: flex;
        justify-content: space-between;
        > :first-child {
            color: #cccccc;
            line-height: 20px;
            cursor: pointer;
        }
        > :last-child {
            color: #1cd2a9;
            line-height: 20px;
            cursor: pointer;
        }
    }
    .getcode {
        height: 48px;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0 10px;
        width: fit-content;
    }
    .el-icon-key {
        color: #3e3a3a;
        font-size: 20px;
        font-weight: bold;
        margin-left: -5px;
    }
    .el-input {
        height: 48px;
        .el-input__prefix {
            left: 10px;
            height: 48px;
            line-height: 57px;
        }
        .el-input__inner {
            height: 48px;
            padding: 0 40px;
        }
    }
    .sub-btn {
        margin-top: 40px;
        width: 400px;
        height: 48px;
        background: linear-gradient(90deg, #0de3b2 0%, #008ee9 100%);
        border-radius: 2px;
    }
    .sub-btn {
        &:active,
        &:focus {
            background: linear-gradient(90deg, #0de3b2 0%, #008ee9 100%);
        }
    }
    .sub-btn:hover {
        box-shadow: 0px 3px 6px rgba(106, 141, 232, 0.78);
        background: linear-gradient(90deg, #0de3b2 0%, #008ee9 100%);
    }
    .el-input-group__append {
        background-color: white;
        border: 0px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .el-input__inner {
        position: relative;
    }
}
</style>