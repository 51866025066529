<template>
  <div id="app">
    <router-view />
    <el-dialog :visible.sync="isLoginDialogVisible" class="login-dialog">
      <Login @success="isLoginDialogVisible = false" v-if="isLoginDialogVisible"></Login>
    </el-dialog>
  </div>
</template>
<script>
import Login from '@/views/login/index.vue'
export default {
  components: {
    Login
  },
  data() {
    return {
      isLoginDialogVisible: false
    }
  },
  provide() {
    return {
      toLogin: () => {
        this.isLoginDialogVisible = true
      }
    }
  },
  created() {
    // document.title = this.$t('el.title');
  }
}

</script>
<style lang="scss">
.login-dialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog{
    width: fit-content;
    background-color: transparent;
  }
}
body {
  // overflow-y: hidden;
}
.el-tooltip__popper {
  max-width: 300px;
}
// #app{
//   width:100vw;
//   height: 100%;
// }
.el-popover.return {
  min-width: 86px;
  width: 86px;
  height: 32px;
  line-height: 32px;
  padding: 0;
  background: #676f84;
  color: #d3dcf5;
  border-color: #676f84;
  cursor: pointer;
  .popper__arrow {
    border-bottom-color: #676f84 !important;
  }
  .popper__arrow::after {
    border-bottom-color: #676f84 !important;
  }
}
.el-popover.return:hover {
  background-color: #fff;
  color: #676f84;
  .popper__arrow::after {
    border-bottom-color: #fff !important;
  }
}
.el-popover.lang-popover,
.el-popover.lang-popover-1 {
  min-width: 86px;
  width: 86px;
  padding: 0;
  background: #676f84;
  color: #d3dcf5;
  border-color: #676f84;
  cursor: pointer;
  .popper__arrow {
    border-bottom-color: #676f84 !important;
  }
  .popper__arrow::after {
    border-bottom-color: #676f84 !important;
  }
}
.el-popover.lang-popover-1:hover {
  .popper__arrow::after {
    border-bottom-color: #fff !important;
  }
}
.lang-word-1,
.lang-word {
  height: 32px;
  line-height: 32px;
  &:hover {
    background-color: #fff;
    color: #676f84;
  }
}
.atooltip.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #d3dcf5;

  color: #141620;
}
.atooltip.el-tooltip__popper[x-placement^="top"] .popper__arrow:after {
  border-top-color: #d3dcf5;
}
.atooltip.el-tooltip__popper {
  padding: 0 27px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  border-radius: 6px;
}
.atooltip {
  background: #d3dcf5 !important;
  color: #141620 !important;
  font-size: 14px !important;
}
</style>
