<template>
    <div class="right">
        <h3 class="title">{{$t('el.login.login')}}</h3>
        <div class="form">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                auto-complete="on"
                label-position="left"
            >
                <el-form-item prop="name">
                    <el-input
                        v-model.trim="form.name"
                        :placeholder="$t('el.login.accountOrEmailP')"
                        clearable
                    >
                        <template slot="prefix">
                            <img src="@/assets/images/login/email.png" alt />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        v-model.trim="form.password"
                        :placeholder="$t('el.inputPW')"
                        :type="visibleAgain ? 'text' : 'password'"
                    >
                        <template slot="prefix">
                            <img src="@/assets/images/login/password.png" alt />
                        </template>
                        <template slot="append">
                            <img
                                class="showIcon"
                                v-if="!visibleAgain"
                                @click="visibleAgain = !visibleAgain"
                                src="@/assets/images/hide.png"
                                alt
                            />
                            <img
                                class="showIcon"
                                v-else
                                @click="visibleAgain = !visibleAgain"
                                src="@/assets/images/show.png"
                                alt
                            />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="code">
                    <div class="flex">
                        <el-input
                            prefix-icon="el-icon-key"
                            v-model.trim="form.code"
                            :placeholder="$t('el.verificationCode')"
                            clearable
                            maxlength="6"
                            @keyup.native.enter="handLogin"
                        ></el-input>

                        <img :src="codeUrl" @click="getCode" class="login-code-img" />
                    </div>
                </el-form-item>

                <el-button
                    id="login_btn"
                    :loading="loading"
                    size="medium"
                    type="primary"
                    style="width: 100%; margin-bottom: 30px"
                    @click.native.prevent="handLogin"
                    class="sub-btn"
                >
                    <span v-if="!loading">{{ $t('el.login.login') }}</span>
                    <span v-else>{{ $t('el.login.loging') }}...</span>
                </el-button>
            </el-form>

            <div class="right-bottom-btn">
                <div
                    @click="$emit('to', 'register')"
                    class="to-register"
                >{{ $t('el.register.register') }}</div>
                <div
                    @click="$emit('to', 'resetPassword')"
                    class="to-reset"
                >{{ $t('el.resetPassword.forgetPW') }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { getCodeImg, login } from "@/api/login";
import { $success, setData } from "@/utils/save";
import { Base64 } from "js-base64";

export default {
    data() {
        const validateUsername = (rule, value, callback) => {
            value.length < 1
                ? callback(new Error(this.$t('el.login.accountOrEmailP')))
                : callback();
        };
        const validatePassword = (rule, value, callback) => {
            value.length === 0
                ? callback(new Error(this.$t('el.inputPW')))
                : callback();
        };
        const validateCode = (rule, value, callback) => {
            value.length === 0
                ? callback(new Error(this.$t('el.verCodeP')))
                : callback();
        };
        return {
            form: {
                name: "",
                password: "",
                code: "",
                uuid: "",
            },
            loading: false,
            //验证码url地址
            codeUrl: "",
            visibleAgain: false,
            rules: {
                name: [{ validator: validateUsername, trigger: "blur" }],
                password: [{ validator: validatePassword, trigger: "blur" }],
                code: [{ validator: validateCode, trigger: "blur" }],
            },
        };
    },
    methods: {
        //获得图片地址
        getCode() {
            getCodeImg().then(({ data }) => {
                this.codeUrl = "data:image/gif;base64," + data.img;
                this.form.uuid = data.uuid;
            });
        },
        //登录
        handLogin() {
            window.sessionStorage.clear();
            const self = this;
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    const { name, password, code, uuid } = self.form;
                    login({
                        username: name,
                        password: Base64.encode(password),
                        code,
                        uuid,
                        client_id: "beosin-vaas",
                        client_secret: "123456",
                        grant_type: "password",
                        login_type: "password",
                        scope: "server",
                        product_version: 'FREE'
                    })
                        .then((res) => {
                            //存取用户信息
                            setData("token", res.access_token);

                            this.$store.commit("changeUser", res);
                            $success(this.$t('el.login.loginSuccess'));
                            this.form = {
                                ...this.from,
                                name: "",
                                password: "",
                                code: "",
                            };
                            this.$emit('success')
                            // window.location.reload()
                        })
                        .catch((err) => {
                            this.getCode();
                            return false;
                        });
                }
            });
        },
    },
    created() {
        this.getCode();
    },
};
</script>
<style scoped lang='scss'>
.right {
    box-sizing: border-box;
    .flex{
        align-items: center;
        display: flex;
        .login-code-img{
            margin-left: 10px;
            height: 48px;
        }
    }
}

.right {
    ::v-deep .el-input__inner {
        border-radius: 0;
        position: relative;
    }

    .el-input--prefix .el-input__inner {
        padding-right: 60px !important;
    }

    .el-input_icon {
        height: 100% !important;
        width: 25px !important;
        text-align: center !important;
        transition: all 0.3s !important;
        line-height: 40px !important;
    }
}

::v-deep input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
}

::v-deep input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-text-fill-color: #606266 !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
}
</style>