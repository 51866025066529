
import request from '@/utils/request'
import { noToken } from '@/utils/request';
const base='/new-vaas'
import {getStore} from "../utils/utils";
import {getData} from '@/utils/save.js'
let language= getStore('language') ?  getStore('language'): 'en_US';


// 无token获取默认数据
export function getDefaultData(url,params) {
    return noToken.get(base +'/common/no-validate/default_contract?from=overseas')
}

//获取监测结果列表
export function getTableList(params) {
    return request({
        url: base+'/contract/list',
        method: 'post',
        params
    })
}
//合约检测项
export function checkType(params) {
    return request({
        url: base+'/contract/errorType',
        method: 'post',
        params,
        headers:{
            'Accept-Language': language
        }
    })
}
//合约检测结果
export function checkResult(params) {
    return request({
        url: base+'/contract/audit/result',
        method: 'post',
        params,
        headers:{
            'Accept-Language': language
        }
    })
}
//检测合约
export function testContract(params) {
    return request({
        url: base+'/contract/audit',
        method: 'post',
        params,
        headers:{
            'Accept-Language': language
        }
    })
}
//剩余免费次数
export function free(params) {
    return request({
        url: base+'/common/free/num',
        method: 'get',
        params
    })
}
//新建菜单
export function createMenu(params) {
    return request({
        url: base+'/menu/create',
        method: 'post',
        params
    })
}
//获得所有层级平铺数据
export function getFileData() {
    return request({
        url: base+'/menu/query/all/extent',
        method: 'post',
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        
    })
}
//获得所有的菜单层级结构(按照层次深度排列)
export function getDatas() {
    return request({
        url: base+'/menu/query/all/depth',
        method: 'post',
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        
    })
}

//创建的默认菜单
export function defaultMenu(params) {
    let url = base+'/menu/create/default'
    if(params){
        url = `${url}?from=${params.from}`
    }
    return request({
        url: url,
        method: 'post',
    })
}
//删除菜单
export function deleteMenu(params) {
    return request({
        url: base+'/menu/delete',
        method: 'post',
        params

    })
}
//查询单个合约
export function queryDocument(params) {
    return request({
        url: base+'/contract/query/single',
        method: 'post',
        params
    })
}
//创建单个合约
export function createDocument(params) {
    return request({
        url: base+'/contract/create',
        method: 'post',
        params
    })
}
//保存所有合约
export function save(params) {
    return request({
        url: base+'/contract/save/all',
        method: 'post',
        params
    })
}
//中断合约检测
export function interrupt(params) {
    return request({
        url: base+'/contract/audit/interrupt',
        method: 'post',
        params
    })
}
// 重命名菜单
export function menuRename(params) {
    return request({
        url: base+'/menu/update',
        method: 'post',
        params
    })
}
//修改项目
export function updateProject(params) {
    return request({
        url: base+'/menu/update/project',
        method: 'post',
        params
    })
}
//通过id数组查询多个合约
export function ManyInquiries(params) {
    return request({
        url: base+'/contract/query/batch',
        method: 'post',
        params
    })
}
//重置默认合约
export function resetDefaultFileById(params) {
  return request({
      url: base+'/menu/reset/default',
      method: 'post',
      params
  })
}

//停止检测
export function gotoStopTesting(params) {
  return request({
      url: base+'/contract/task_stop',
      method: 'post',
      params
  })
}


