<template>
    <div class="right">
        <div class="title">VaaS</div>
        <div class="topic">{{ $t('el.projectName') }}</div>
        <div class="form">
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="phone">
                    <el-input
                        prefix-icon="el-icon-user"
                        v-model.trim="form.phone"
                        :placeholder="$t('el.phoneP')"
                        clearable
                    ></el-input>
                </el-form-item>

                <div class="flex">
                    <el-form-item prop="code">
                        <el-input
                            prefix-icon="el-icon-key"
                            v-model.trim="form.code"
                            :placeholder="$t('el.verificationCode')"
                            clearable
                            style="width: 90%"
                        ></el-input>
                    </el-form-item>

                    <div class="login-code">
                        <div
                            class="getcode"
                            v-if="!tip"
                            @click="getRegisterCode"
                        >{{ $t('el.getVerCode') }}</div>
                        <div class="getcode" v-else>{{ number }}s{{ $t('el.reGetVerCode') }}</div>
                    </div>
                </div>
                <el-button
                    id="login_btn"
                    :loading="loading"
                    size="medium"
                    type="primary"
                    style="width: 100%; margin-bottom: 30px"
                    clearable
                    @click.native.prevent="handLogin"
                >
                    <span v-if="!loading">{{ $t('el.login.login') }}</span>
                    <span v-else>{{ $t('el.login.loging') }}...</span>
                </el-button>
            </el-form>
            <div class="bottom">
                <div class="left-i" @click="$emit('to', 'login')">{{ $t('el.login.accountLogin') }}</div>
                <div class="right-i">
                    <div></div>
                    <div @click="$emit('to', 'register')">{{ $t('el.register.register') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { loginCode, loginPhone } from "@/api/login";
import { $success, setData } from '@/utils/save'

export default {
    data() {
        const validatePhone = (rule, value, callback) => {
            if (!value) callback(new Error(this.$t('el.phoneP')));
            !this.phoneReg.test(value) ? callback(new Error(this.$t('el.tipPhoneF'))) : callback();
        };
        const validateCode = (rule, value, callback) => {
            value.length === 0 ? callback(new Error(this.$t('el.verCodeP'))) : callback();
        };
        return {
            form: {
                phone: "",
                code: "",
                uuid: "",
            },
            number: '',
            //是否在获取验证码
            tip: false,
            loading: false,
            //验证码url地址
            codeUrl: "",
            rules: {
                phone: [
                    { validator: validatePhone, trigger: 'blur' }
                ],
                code: [
                    { validator: validateCode, trigger: 'blur' }
                ]
            }

        };
    },
    methods: {
        //获得注册的验证码
        getRegisterCode() {
            let self = this;
            const userName = this.form.phone.trim();
            this.$refs["form"].validateField("phone", (error) => {
                if (!error) {
                    loginCode({
                        phoneNumber: userName,
                    }).then((res) => {
                        $success(this.$t('el.getVerCodeSuccess'));
                        this.tip = true;
                        this.form.uuid = res;
                        this.number = 60;
                        self.codeInterval = window.setInterval(() => {
                            if (this.number > 1) {
                                this.number--;
                            } else {
                                window.clearInterval(self.codeInterval);
                                this.tip = false;
                            }
                        }, 1000);
                    });
                }
            });
        },
        //登录
        handLogin() {
            this.$refs['form'].validate(valid => {
                if (valid) {
                    const { phone, code, uuid } = this.form;
                    loginPhone({
                        phonenumber: phone,
                        code,
                        uuid: uuid.data,
                        login_type: 'mobile_phone_code',
                        client_id: 'beosin-vaas',
                        client_secret: '123456',
                        grant_type: 'password',
                        scope: 'server',
                        product_version: 'FREE'
                    }).then(res => {
                        //登录成功
                        setData('token', res.access_token)
                        $success(this.$t('el.login.loginSuccess'));
                        $emit("success")
                    }).catch(err => {
                        return false
                    })

                }

            });

        },
    },

};
</script>
<style scoped lang='scss'>
.getcode {
    height: 40px;
    width: 110px;
    font-size: 14px;
    color: white;
    line-height: 40px;
    text-align: center;
    padding: 0;
    background: #6a8de8;
    // margin-top:13px;
    border-radius: 4px;
}

.inner {
    width: 100vw;
    height: 100vh;
    background-color: #0e203c;
    position: relative;
    overflow: hidden;
    min-width: 1024px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleOne {
    position: absolute;
    left: 1rem;
    width: 5.025rem;
    height: 5.025rem;
    border-radius: 50%;
    background: #344e98;
    top: -3.5rem;
}

.circleTwo {
    width: 13.175rem;
    height: 13.175rem;
    background: #344e98;
    border-radius: 50%;
    position: absolute;
    left: -7.8125rem;
    bottom: -50%;
}

.circleThree {
    width: 1.775rem;
    height: 1.825rem;
    border-radius: 50%;
    background: #344e98;
    position: absolute;
    right: 2.5875rem;
    top: 1.6875rem;
}

.circelFour {
    width: 14.8rem;
    height: 14.8rem;
    background-color: #344e98;
    border-radius: 50%;
    right: -7.4rem;
    position: absolute;
    bottom: -8.625rem;
}


.title {
    margin-top: 51px;
    text-align: center;
    font-size: 26px;
    line-height: 35px;
    color: #193380;
    letter-spacing: 4px;
    font-weight: 700;
}

.topic {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    color: #193380;
    text-align: center;
    letter-spacing: 2px;
}

.form {
    margin-top: 50px;
}

.right {
    .el-input {
        height: 40px;
        color: black;
    }

    ::v-deep .el-input__inner {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #c1ccec;
        border-radius: 0;
        height: 40px;
        line-height: 40px;
    }

    .flex {
        display: flex;
        justify-content: space-between;
    }

    .el-button--primary {
        color: #fff;
        background-color: #6a8de8;
        border-color: #6a8de8;
    }

    ::v-deep .el-button {
        height: 50px;
        margin-bottom: 26px;
    }

    .bottom {
        display: flex;
        justify-content: space-between;

        .left-i {
            font-size: 12px;
            font-weight: 400;
            line-height: 21px;
            color: #c1ccec;
        }

        div {
            cursor: pointer;
        }

        .right-i {
            display: flex;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #3285ff;

            div:first-child {
                margin-right: 20px;
            }
        }
    }
}

::v-deep input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-text-fill-color: #606266 !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
}

::v-deep .el-input__prefix {
    left: -3px;

    .el-input__icon {
        font-size: 20px;
    }
}
</style>