<template>
  <div class="right">
    <template v-if="!isSuccess">
      <div class="title">{{ $t('el.resetPassword.reset') }}</div>
      <div class="form">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="name">
            <el-input
              v-model.trim="form.name"
              :placeholder="$t('el.login.accountOrEmailP')"
              clearable
              maxlength="40"
            >
              <template slot="prefix">
                <img src="@/assets/images/login/email.png" alt />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="flex">
              <el-input
                prefix-icon="el-icon-key"
                v-model.trim="form.code"
                :placeholder="$t('el.verificationCode')"
                clearable
                maxlength="6"
              ></el-input>

              <img :src="codeUrl" @click="getPicCode" class="login-code-img" />
            </div>
          </el-form-item>
          <el-form-item prop="verification_code">
            <div class="flex">
              <el-input
                prefix-icon="el-icon-key"
                v-model.trim="form.verification_code"
                :placeholder="$t('el.verificationCode')"
                clearable
                style="width:260px"
                maxlength="6"
              ></el-input>
              <div class="getcode" v-if="!tip" @click="getCode">
                <img src="@/assets/images/login/email2.png" alt />
                {{ $t('el.getVerCode') }}
              </div>
              <div class="getcode" v-else>{{ number }}s {{ $t('el.reGetVerCode') }}</div>
            </div>
          </el-form-item>

          <el-form-item prop="newPwd">
            <el-input
              v-model.trim="form.newPwd"
              :placeholder="$t('el.newPWP')"
              maxlength="12"
              :type="visibleAgain ? 'text' : 'password'"
            >
              <template slot="prefix">
                <img src="@/assets/images/login/password.png" alt />
              </template>
              <template slot="append">
                <img
                  class="showIcon"
                  v-if="!visibleAgain"
                  @click="visibleAgain = !visibleAgain"
                  src="@/assets/images/hide.png"
                  alt
                />
                <img
                  class="showIcon"
                  v-else
                  @click="visibleAgain = !visibleAgain"
                  src="@/assets/images/show.png"
                  alt
                />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model.trim="form.password"
              :placeholder="$t('el.confirmNewPWP')"
              maxlength="12"
              :type="visible ? 'text' : 'password'"
            >
              <template slot="prefix">
                <img src="@/assets/images/login/password.png" alt />
              </template>
              <template slot="append">
                <img
                  class="showIcon"
                  v-if="!visible"
                  @click="visible = !visible"
                  src="@/assets/images/hide.png"
                  alt
                />
                <img
                  class="showIcon"
                  v-else
                  @click="visible = !visible"
                  src="@/assets/images/show.png"
                  alt
                />
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <el-button
          id="login_btn"
          :loading="loading"
          size="medium"
          type="primary"
          class="sub-btn"
          style="width: 100%; margin-bottom: 30px;margin-top: 12px"
          @click.native.prevent="handRegister"
        >
          <span v-if="!loading">{{ $t('el.resetPassword.reset') }}</span>
          <span v-else>{{ $t('el.resetPassword.reseting') }}...</span>
        </el-button>
        <div class="right-bottom-btn">
          <div @click="$emit('to', 'register')">{{ $t('el.register.register') }}</div>
          <div @click="$emit('to', 'login')">{{ $t('el.resetPassword.goLogin') }}</div>
        </div>
      </div>
    </template>
    <Success
      v-else
      :title="$t('el.resetPassword.resetSuccess')"
      @to="$emit('to', 'login')"
    ></Success>
  </div>
</template>
<script>
import { forgetPasswordApi, verifyCodePassword, getCodeImg } from '../../api/login'
import Success from './success.vue'
export default {
  components: {
    Success
  },
  data() {
    const validateEmailnumber = (rule, value, callback) => {
      if (this.emailReg.test(value)) {
        callback()
      } else {
        callback(new Error(this.$t('el.login.accountOrEmailTip')))
      }
    }
    const validateNewPwd = (rule, value, callback) => {
      if (!this.pwdReg.test(value)) {
        callback(new Error(this.$t('el.tipPWF')))
      } else {
        callback()
      }
    }
    const validatePwd = (rule, value, callback) => {
      if (!this.pwdReg.test(value)) {
        callback(new Error(this.$t('el.tipPWF')))
      } else {
        if (this.form.newPwd === this.form.password) {
          callback()
        } else {
          callback(new Error(this.$t('el.tipPW')))
        }
      }
    }
    return {
      form: {
        name: '',
        password: '',
        code: '',
        uuid: '',
        newPwd: '',
        verification_code: ''
      },
      codeUrl:'',
      loading: false,
      rules: {
        name: [
          { required: true, message: this.$t('el.login.accountOrEmailP'), trigger: 'blur' },
          { validator: validateEmailnumber, trigger: 'blur' }
        ],
        code: [{ required: true, message: this.$t('el.verCodeP'), trigger: 'blur' }],
        verification_code: [{ required: true, message: this.$t('el.verCodeP'), trigger: 'blur' }],
        newPwd: [
          { required: true, message: this.$t('el.newPWP'), trigger: 'blur' },
          { validator: validateNewPwd, trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('el.confirmNewPWP'), trigger: 'blur' },
          { validator: validatePwd, trigger: 'blur' }
        ]
      },
      //是否重新获得验证码
      tip: false,
      number: null,
      visibleAgain: false,
      visible: false,
      codeInterval: null,
      isSuccess: false
    }
  },
  created() {
    this.getPicCode()
  },
  methods: {
    // 图片验证码
    getPicCode() {
      getCodeImg().then(({ data }) => {
        this.codeUrl = 'data:image/gif;base64,' + data.img
        this.form.picuuid = data.uuid
      })
    },
    //处理重置密码
    handRegister() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const { name, password, code } = this.form
          this.loading = true
          forgetPasswordApi({
            account: name,
            password: Base64.encode(password),
            re_password: Base64.encode(password),
            verification_code: this.form.verification_code,
            code: this.form.code
          })
            .then(res => {
              this.loading = false
              this.isSuccess = true
            })
            .catch(err => {
              this.loading = false
              this.getPicCode()
              return false
            })
        }
      })
    },
    //获得改密码的验证码
    getCode() {
      const _this = this
      this.$refs['form'].validateField('name', error => {
        if (error) return
        this.$refs['form'].validateField('code', error => {
          if (error) return
          verifyCodePassword({
            userName: this.form.name,
            verification_code: this.form.verification_code,
            uuid: this.form.picuuid,
            code: this.form.code,
          }).then(res => {
            this.$message.success(this.$t('el.getVerCodeSuccess'))
            this.tip = true
            this.form.uuid = res.data
            this.number = 60
            this.codeInterval = setInterval(() => {
              if (this.number > 0) {
                this.number--
              } else {
                clearInterval(_this.codeInterval)
                this.tip = false
              }
            }, 1000)
          })
          .catch(() => {
            this.getPicCode()
          })
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.inner {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  min-width: 1024px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right {
  box-sizing: border-box;
  .form {
    width: 400px;
  }
  ::v-deep .el-icon-key {
    color: #3e3a3a;
    font-size: 20px;
    font-weight: bold;
    margin-left: -5px;
  }
  .el-input {
    height: 48px;
    ::v-deep .el-input__prefix {
      left: 10px;
      line-height: 48px;
      line-height: 57px;
    }
    ::v-deep .el-input__inner {
      height: 48px;
      padding: 0 40px;
    }
  }
}

.bg {
  margin: 79px 0 35px;
  text-align: center;
}

.bg img {
  width: 517px;
}

.topic {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #193380;
  text-align: center;
  letter-spacing: 2px;
}

.form {
  margin-top: 25px;
}

.right {
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    .login-code-img {
      margin-left: 10px;
      height: 48px;
    }
    .getcode {
      height: 48px;
      font-size: 14px;
      cursor: pointer;
      color: #1cd2a9;
      line-height: 48px;
      text-align: center;
      padding: 0 5px;
      border-radius: 4px;
      img {
        vertical-align: middle;
        margin-right: 3px;
      }
    }
  }
}

::v-deep .el-input-group__append,
.el-input-group__prepend {
  background-color: white;
  border: 0px;
  position: absolute;
  right: 10px;
  top: 15px;
}

::v-deep .el-input__inner {
  position: relative;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset !important;
}

::v-deep input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #606266 !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}

::v-deep .el-input__prefix {
  left: 2px;

  .el-input__icon {
    font-size: 20px;
  }
}
</style>
